<template>
  <hs-timeline :loading="loading" :placeholders="3" :steps="steps" />
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    loading: Boolean,
    data: Object,
  },
  data() {
    return {
      steps: [],
    };
  },
  watch: {
    data(value) {
      this.steps = this.normalizeData(value);
    },
  },
  methods: {
    normalizeData(data) {
      let steps = [];

      data.lessons_progresses.map(l => {
        steps.push({
          icon: l.completed ? 'check' : 'clock',
          title: this.$t(
            `sparkmembers.members-details.resume.timeline.lessons_progresses.${
              l.completed ? 'completed' : 'not-completed'
            }`,
            { title: l.lesson.title }
          ),
          description: this.$t('sparkmembers.members-details.resume.timeline.lessons_progresses.description', {
            date: dayjs(l.updated_at).format('D, MMM, YYYY - HH:mm'),
            course: l.progress_card.course.name,
          }),
          variant: l.completed ? 'purple' : 'secondary-purple',
          updated_at: l.updated_at,
        });
      });

      data.orders.map(p => {
        steps.push({
          ...this.normalizeOrdersData(p.status),
          title: this.$t(`sparkmembers.members-details.resume.timeline.orders.title.${p.status}`),
          description: this.$t('sparkmembers.members-details.resume.timeline.orders.description', {
            date: dayjs(p.lastUpdatedAt).format('DD/MM/YYYY [às] HH:mm'),
            product: p.productName,
          }),
          updated_at: p.lastUpdatedAt,
        });
      });

      data.topics.map(f => {
        steps.push({
          icon: 'comments',
          title: this.$t(`sparkmembers.members-details.resume.timeline.topics.title`),
          description: this.$t('sparkmembers.members-details.resume.timeline.topics.description', {
            date: dayjs(f.updated_at).format('DD/MM/YYYY [às] HH:mm'),
            forum: f.forum_section_title,
          }),
          variant: 'secondary',
          updated_at: f.updated_at,
        });
      });

      return steps.sort(
        (a, b) => dayjs(b.updated_at).format('YYYYMMDDHHmm') - dayjs(a.updated_at).format('YYYYMMDDHHmm')
      );
    },
    normalizeOrdersData(status) {
      const types = ['initiated', 'processing', 'no_wait_processing', 'confirmed', 'abandoned', 'canceled', 'paid'];
      const objects = [
        {
          icon: 'clock',
          variant: 'secondary-purple',
        },
        {
          icon: 'clock',
          variant: 'warning',
        },
        {
          icon: 'clock',
          variant: 'warning',
        },
        {
          icon: 'check',
          variant: 'success',
        },
        {
          icon: 'times',
          variant: 'cherry',
        },
        {
          icon: 'times',
          variant: 'cherry',
        },
        {
          icon: 'check',
          variant: 'success',
        },
      ];

      return objects[types.indexOf(status)];
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .bg-secondary-purple {
  background-color: #ead3fe !important;
  i {
    color: #4d1aa1 !important;
  }
}
::v-deep span {
  white-space: pre-wrap;
}
</style>
